<template>
  <div class="storage container-fluid">
    <div v-if="naverUserId != null">
      <div class="title-area">
        <div class="title-box">
          <a href="/" class="previous"
            ><font-awesome-icon
              icon="fa-solid fa-chevron-left"
              size="lg"
            />홈</a
          >
          <h2>내 저장소</h2>
          <!--          <a href="/editor" class="sharebtn"-->
          <!--            ><font-awesome-icon icon="fa-solid fa-plus"-->
          <!--          /></a>-->
        </div>
        <div class="filter">
          <div class="filter-container">
            <ul>
              <li @click="typeFilter($event, '')" class="types">
                <p class="on">전체보기</p>
                <span></span>
              </li>
              <li @click="typeFilter($event, 'phr')" class="types ">
                <p>PHR</p>
                <span></span>
              </li>
              <li @click="typeFilter($event, 'sns')" class="types ">
                <p>SNS</p>
                <span></span>
              </li>
              <li @click="typeFilter($event, 'shop')" class="types ">
                <p>쇼핑</p>
                <span></span>
              </li>
              <li @click="typeFilter($event, 'edu')" class="types ">
                <p>교육</p>
                <span></span>
              </li>
              <li @click="typeFilter($event, 'ott')" class="types ">
                <p>OTT</p>
                <span></span>
              </li>
              <li @click="typeFilter($event, 'bank')" class="types ">
                <p>금융</p>
                <span></span>
              </li>
              <li @click="typeFilter($event, 'public')" class="types ">
                <p>공공기록</p>
                <span></span>
              </li>
              <!-- <li class="fil-el all on" @click="onClick('all')">
                <p>전체보기</p>
                <span></span>
              </li>
              <li class="fil-el sns" @click="onClick('sns')">
                <p>SNS</p>
                <span></span>
              </li>
              <li class="fil-el shop" @click="onClick('shop')">
                <p>쇼핑</p>
                <span></span>
              </li>
              <li class="fil-el edu" @click="onClick('edu')">
                <p>교육</p>
                <span></span>
              </li>
              <li class="fil-el OTT" @click="onClick('OTT')">
                <p>OTT</p>
                <span></span>
              </li>
              <li class="fil-el bank" @click="onClick('bank')">
                <p>금융</p>
                <span></span>
              </li>
              <li class="fil-el public" @click="onClick('public')">
                <p>공공기록</p>
                <span></span>
              </li>
              <li class="health">
                <p>건강</p>
                <span></span>
              </li> -->
            </ul>
          </div>
        </div>
      </div>

      <div class="card-area">
        <a href="/medicalcheckup" class="content phr screening on">
          <div class="deck screening-card">
            <div class="thumbnail">
              <span>
                <img
                  src="https://kr.object.ncloudstorage.com/candiy-bucket/assets/icon/screening_icon.png"
                  alt="건강검진결과"
                />
              </span>
            </div>
            <div class="text">
              <h3>건강검진결과</h3>
            </div>
          </div>
        </a>
        <a href="/naver" class="content shop naver on">
          <div class="deck naver-card">
            <div class="thumbnail">
              <span>
                <img
                  src="https://kr.object.ncloudstorage.com/candiy-bucket/assets/logo/btnG_%EC%95%84%EC%9D%B4%EC%BD%98%EC%82%AC%EA%B0%81.png"
                  alt="네이버"
                />
              </span>
            </div>
            <div class="text">
              <h3>네이버페이</h3>
              <!--              <span class="connectStatus on" v-if="lineUserId != null"-->
              <!--              >연결중</span-->
              <!--              >-->
              <!-- <span>폴더크기 : 9999KB</span> -->
            </div>
          </div>
        </a>
        <a href="#" class="content phr vaccine on">
          <div class="deck vaccine-card">
            <div class="thumbnail">
              <span>
                <img
                  src="https://kr.object.ncloudstorage.com/candiy-bucket/assets/icon/dignosis_icon.png"
                  alt="접종내역"
                />
              </span>
            </div>
            <div class="text">
              <h3>접종내역</h3>
            </div>
          </div>
        </a>
        <a href="/gmarket" class="content shop gmarket on">
          <div class="deck gmarket-card">
            <div class="thumbnail">
              <span>
                <img
                  src="https://kr.object.ncloudstorage.com/candiy-bucket/assets/logo/Gmarket_app_logo.png"
                  alt="지마켓"
                />
              </span>
            </div>
            <div class="text">
              <h3>지마켓</h3>
            </div>
          </div>
        </a>
        <a href="/medicalrecords" class="content phr on">
          <div class="deck">
            <div class="thumbnail">
              <span>
                <img
                  src="https://kr.object.ncloudstorage.com/candiy-bucket/assets/icon/prescription_icon.png"
                  alt="진료내역"
                />
              </span>
            </div>
            <div class="text">
              <h3>진료내역</h3>
            </div>
          </div>
        </a>
        <a href="#" class="content phr prescription on">
          <div class="deck prescription-card">
            <div class="thumbnail">
              <span>
                <img
                  src="https://kr.object.ncloudstorage.com/candiy-bucket/assets/icon/vaccine_icon.png"
                  alt="처방내역"
                />
              </span>
            </div>
            <div class="text">
              <h3>처방내역</h3>
            </div>
          </div>
        </a>
        <a href="/kakao" class="content sns kakao on">
          <div class="deck kakao-card">
            <div class="thumbnail">
              <span>
                <img
                  src="https://kr.object.ncloudstorage.com/candiy-bucket/assets/logo/kakaotalk_app_logo.png"
                  alt="카카오톡"
                />
              </span>
            </div>
            <div class="text">
              <h3>카카오톡</h3>
              <!--              <span class="connectStatus on" v-if="kakaoUserId != null"-->
              <!--              >연결중</span-->
              <!--              >-->
              <!-- <span>폴더크기 : 9999KB</span> -->
            </div>
          </div>
        </a>
        <a href="/coupang" class="content shop coupang on">
          <div class="deck coupang-card">
            <div class="thumbnail">
              <span>
                <img
                  src="https://kr.object.ncloudstorage.com/candiy-bucket/assets/logo/Coupang_logo.png"
                  alt="쿠팡"
                />
              </span>
            </div>
            <div class="text">
              <h3>쿠팡</h3>
            </div>
          </div>
        </a>
        <a href="/line" class="content sns line on">
          <div class="deck line-card">
            <div class="thumbnail">
              <span>
                <img
                  src="https://kr.object.ncloudstorage.com/candiy-bucket/assets/logo/Line_app_logo.png"
                  alt="라인"
                />
              </span>
            </div>
            <div class="text">
              <h3>LINE</h3>
              <!--              <span class="connectStatus on" v-if="lineUserId != null"-->
              <!--              >연결중</span-->
              <!--              >-->
              <!-- <span>폴더크기 : 9999KB</span> -->
            </div>
          </div>
        </a>
        <a href="/ssg" class="content shop ssg on">
          <div class="deck ssg-card">
            <div class="thumbnail">
              <span>
                <img
                  src="https://kr.object.ncloudstorage.com/candiy-bucket/assets/logo/SSG_app_square_logo.png"
                  alt="SSG"
                />
              </span>
            </div>
            <div class="text">
              <h3>SSG</h3>
            </div>
          </div>
        </a>
        <a href="#" class="content shop auction">
          <div class="deck auction-card">
            <div class="thumbnail">
              <span>
                <img
                  src="https://kr.object.ncloudstorage.com/candiy-bucket/assets/logo/Auction_app_logo.png"
                  alt="옥션"
                />
              </span>
            </div>
            <div class="text">
              <h3>옥션</h3>
              <!-- <span>폴더크기 : 9999KB</span> -->
            </div>
          </div>
        </a>
        <a href="#" class="content shop interpark">
          <div class="deck interpark-card">
            <div class="thumbnail">
              <span>
                <img
                  src="https://kr.object.ncloudstorage.com/candiy-bucket/assets/logo/Interpark_app_logo.png"
                  alt="인터파크"
                />
              </span>
            </div>
            <div class="text">
              <h3>인터파크</h3>
              <!-- <span>폴더크기 : 9999KB</span> -->
            </div>
          </div>
        </a>
        <a href="#" class="content sns whatsapp">
          <div class="deck whatsapp-card">
            <div class="thumbnail">
              <span>
                <img
                  src="https://kr.object.ncloudstorage.com/candiy-bucket/assets/logo/Whatsapp_app_green_logo.png"
                  alt="왓츠앱"
                />
              </span>
            </div>
            <div class="text">
              <h3>WhatsApp</h3>
              <!-- <span>폴더크기 : 9999KB</span> -->
            </div>
          </div>
        </a>
        <a href="#" class="content sns fbmessenger">
          <div class="deck fbm-card">
            <div class="thumbnail">
              <span>
                <img
                  src="https://kr.object.ncloudstorage.com/candiy-bucket/assets/logo/Facebook_app_gradient_logo.png"
                  alt="페북메신저"
                />
              </span>
            </div>
            <div class="text">
              <h3>Messenger</h3>
              <!-- <span>폴더크기 : 9999KB</span> -->
            </div>
          </div>
        </a>
        <a href="#" class="content sns telegram">
          <div class="deck telegram-card">
            <div class="thumbnail">
              <span>
                <img
                  src="https://kr.object.ncloudstorage.com/candiy-bucket/assets/logo/Telegram_app_color_logo.png"
                  alt="텔레그램"
                />
              </span>
            </div>
            <div class="text">
              <h3>텔레그램</h3>
              <!-- <span>폴더크기 : 9999KB</span> -->
            </div>
          </div>
        </a>
        <a href="#" class="content sns instagram">
          <div class="deck insta-card">
            <div class="thumbnail">
              <span>
                <img
                  src="https://kr.object.ncloudstorage.com/candiy-bucket/assets/logo/Instagram_app_gradient_logo.png"
                  alt="인스타그램"
                />
              </span>
            </div>
            <div class="text">
              <h3>Instagram</h3>
              <!-- <span>폴더크기 : 9999KB</span> -->
            </div>
          </div>
        </a>
        <a href="#" class="content sns twitter">
          <div class="deck twitter-card">
            <div class="thumbnail">
              <span>
                <img
                  src="https://kr.object.ncloudstorage.com/candiy-bucket/assets/logo/Twitter_app_blue_logo.png"
                  alt="트위터"
                />
              </span>
            </div>
            <div class="text">
              <h3>Twitter</h3>
              <!-- <span>폴더크기 : 9999KB</span> -->
            </div>
          </div>
        </a>
      </div>
    </div>
    <div v-else>
      <NaverLoginButton />
    </div>
  </div>
</template>

<script>
import loginMixin from '@/mixins/loginMixin';

export default {
  //  store,

  name: 'Storage',
  components: {
    //'Browser': () => import('@/components/explorer/Browser'),
    //'Explorer': () => import('@/components/explorer/Explorer'),
    //SolidLoginButton: () => import("@/components/solid/SolidLoginButton"),
    NaverLoginButton: () => import('@/components/naver/NaverLoginButton'),
  },
  mixins: [loginMixin],
  data: function() {
    return {
      //  storage: "",
      //folder: {}
    };
  },
  created() {
    // if (this.webId == null) {
    //   this.popupLogin();
    // }
    if (localStorage.getItem('kakao_access_token') != null) {
      const token = localStorage.getItem('kakao_access_token');
      //console.log(token);
      this.$store.dispatch('kakao/getKakaoUser', token);
    }

    if (localStorage.getItem('access_token') != null) {
      const token = localStorage.getItem('access_token');
      this.$store.dispatch('line/getLineUser', token);
    }

    if (localStorage.getItem('naver_access_token') != null) {
      const token = localStorage.getItem('naver_access_token');
      this.$store.dispatch('naver/getNaverUser', token);
    }

    //  this.solid= window.solid
    //  this.webId =
    //  fc = new SolidFileClient(auth)
    //  example     await solid.data.from(this.fileUrl)[index]['http://www.w3.org/2005/01/wf/flow#message'].set(namedNode(messUri))
  },
  methods: {
    // onClick(cls) {
    //   console.log(cls);
    //   document.getElementsByClassName("fil-el").forEach((i) => {
    //     i.classList.remove("on");
    //   });
    //   document.querySelector(`li.${cls}`).classList.add("on");
    //   if (cls !== "all" && cls !== "sns") {
    //     document.querySelector(".card-area").style.display = "none";
    //   } else {
    //     document.querySelector(".card-area").style.display = "grid";
    //   }
    // },
    typeFilter(type, value) {
      let types = document.querySelectorAll('.types p');
      types.forEach((element) => element.classList.remove('on'));

      type.target.classList.add('on');

      if (value == '') {
        document
          .querySelectorAll('.content')
          .forEach((e) => (e.style.display = 'block'));
      } else {
        document
          .querySelectorAll('.content')
          .forEach((e) => (e.style.display = 'none'));
        document
          .querySelectorAll(`.${value}`)
          .forEach((e) => (e.style.display = 'block'));
      }

      // let input_search = document.querySelector("#search_input");
      //
      // input_search.value = value;

      // some code to filter users
    },
  },
  computed: {
    webId() {
      return this.$store.state.solid.webId;
    },
    storage() {
      return this.$store.state.solid.storage;
    },
    folder() {
      return this.$store.state.solid.folder;
    },
    kakaoUserId() {
      return this.$store.state.kakao.kakaoUserId;
    },
    lineUserId() {
      return this.$store.state.line.lineUserId;
    },
    naverUserId() {
      return this.$store.state.naver.naverUserId;
    },
  },
  watch: {
    // whenever question changes, this function will run
    /*  webIb: async function (webId) {
  this.storage =   await this.solid.data[webId].storage
  console.log("Storage",`${this.storage}`)
  this.updateBrowser()
}*/
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
.storage {
  margin: auto;
  width: 100%;
}
span.connectStatus {
  width: 50px;
  height: 20px;
  line-height: 20px;
  background: limegreen;
  border-radius: 10px;
  position: absolute;
  right: 30px;
  font-size: 0.7rem;
  text-align: center;
  color: #fff;
  display: block;
}
/* title area */
.title-area {
  width: 100%;
  border-radius: 0 0 25px 25px;
  padding-bottom: 20px;
  position: relative;
  background: #fcfcfc;
}
.title-box {
  margin: 2.7778vw auto 5.5556vw;
  height: 35px;
  position: relative;
  width: 93%;
}
.title-box a.previous {
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 18px;
  color: #007bff;
  font-weight: 400;
}
.title-box a svg {
}
.title-box h2 {
  font-size: 18px;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 600;
}
/* 공유버튼 */
.title-box .sharebtn {
  display: block;
  width: 35px;
  height: 35px;
  border: none;
  background: #5ebdeb;
  border-radius: 7px;
  color: #fcfcfc;
  text-align: center;
  line-height: 35px;
  box-shadow: 3px 3px 15px rgba(94, 189, 235, 0.3);
  position: absolute;
  top: 50%;
  right: 0;
  left: auto;
  transform: translateY(-50%);
}
.title-box .sharebtn svg {
  color: #fff;
}

.title-area .filter {
  width: 90%;
  max-width: 640px;
  margin: auto;
  position: relative;
  height: 40px;
  border: none;
}
.title-area .filter::before {
  content: '';
  display: block;
  width: 7%;
  height: 35px;
  background: rgb(252, 252, 252);
  background: linear-gradient(
    90deg,
    rgba(252, 252, 252, 0) 0%,
    rgba(252, 252, 252, 1) 50%,
    rgba(255, 255, 255, 1) 100%
  );
  position: absolute;
  top: 0;
  right: -1px;
  pointer-events: none;
}
.title-area .filter-container {
  height: fit-content;
  overflow: scroll;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.title-area .filter-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.title-area .filter-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.title-area .filter ul {
  list-style: none;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  min-width: fit-content;
  width: 100%;
}
.title-area .filter ul li {
  margin-right: 20px;
  display: inline-block;
  white-space: nowrap;
}
.title-area .filter ul li p {
  color: #d2d2d2;
  font-weight: 600;
  font-size: 16px;
}
.title-area .filter ul li span {
  display: none;
}

/* filter selected */
.title-area .filter ul li.on {
}
.title-area .filter ul li p.on {
  color: #5ebdeb;
}
.title-area .filter ul li p.on + span {
  display: block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #5ebdeb;
  margin: auto;
  margin-top: 5px;
}

/* card-area */
.card-area {
  width: 100%;
  padding: 25px 20px;
  margin-top: -25px;
  background: #f2f2f2;
  text-align: left;
  min-height: 450px;
  max-width: 1080px;
  --auto-grid-min-size: 140px;
  display: grid;
  grid-gap: 5px 10px;
  grid-template-columns: repeat(
    auto-fill,
    minmax(var(--auto-grid-min-size), 1fr)
  );
}
.card-area .content {
  display: block;
  width: fit-content;
  min-width: 140px;
  margin: 20px auto;
  height: 140px;
  position: relative;
}

.card-area .kakao {
}
.card-area .line {
}
.card-area .deck {
  width: 100%;
  height: 165px;
  background: #fff;
  border-radius: 10px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  padding: 5px;
  z-index: 1;
  box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.1);
}
.card-area .deck .thumbnail {
  height: 95px;
  border-radius: 5px;
  margin-bottom: 15px;
  position: relative;
  line-height: 95px;
  background-color: #ececec;
}
/* 썸네일 가리기 */
.card-area .content .deck .thumbnail::before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
}
.card-area .content.on .deck .thumbnail::before {
  display: none;
}
.card-area .deck .thumbnail span {
  display: block;
  width: 60px;
  height: 60px;
  margin: auto;
}

.card-area .deck .thumbnail span img {
  width: 100%;
}
.card-area .deck .text {
  padding: 0 5px 5px;
}
/*브랜드명 색깔 */
.card-area .content .deck .text h3 {
  font-size: 16px;
  font-weight: 600;
  color: #999;
  margin-bottom: -3px;
}
.card-area .content.on .deck .text h3 {
  color: #333;
}
.card-area .deck .text span {
  font-size: 12px;
  font-weight: 400;
  color: #999;
}
.card-area .kakao-card {
}
.card-area .kakao-card .thumbnail {
  background: #ffe812;
}
.card-area .line-card {
}
.card-area .line-card .thumbnail {
  background: #06c755;
}
.card-area .naver-card .thumbnail {
  background: #03c75a;
}
.card-area .interpark-card .thumbnail {
  background: #fff;
}
.card-area .phr .thumbnail {
  background: #ccebfc;
}
.card-area .phr .thumbnail img {
  scale: 1.3;
}
</style>
